import React, { Component } from 'react';
import MaterialTable from "material-table";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Detail from '../Details/Detail';
import {getApplicantById, getApplicants, updateApplicant, deleteApplicant, addApplicant} from '../../API/applicant';
import {getEmailFormats,EMAIL_BACKUP_ADDR} from '../../API/email';
import {getSettingById} from '../../API/setting';
import {makeLookupFormat,addAppLogEntry} from '../../Helper';
import CountrySelect from '../CountrySelect';
import {Collapse,IconButton} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import {Slide, Dialog, DialogContent, DialogTitle, ListItem, ListItemText, List } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class ApplicationList extends Component {
  static displayName = ApplicationList.name;

  tableRef = React.createRef();
  defaultFilters = [];

  constructor(props) {
    super(props);
    this.state = { 
      data: [], 
      defaultStatus:0, 
      lookupStatus:null, 
      defaultGrade:0, 
      lookupGrades:null, 
      loading: true, 
      lookupEmailFormat:null,
      showFilter:false, 
      mailFormatSelectionOpen:false,
      selectedRows:[], 
      dialog: {open:false, text:''} 
    };

    this.onRowAdd = this.onRowAdd.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);
    this.onRowUpdate = this.onRowUpdate.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.openEmail = this.openEmail.bind(this);

    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#5f2c0d',
        },
        secondary: {
          main: '#5f2c0d',
        }
      }
    });

    this.onDialogClose = this.onDialogClose.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }
  
  renderTable(data) {
    let {dialog, defaultGrade, defaultStatus, lookupGrades, lookupStatus, showFilter,lookupEmailFormat, mailFormatSelectionOpen} = this.state;

    console.log(data)

    return (
      <div style={{ maxWidth: "100%" }}>
        <Dialog 
          onClose={() => this.setState({mailFormatSelectionOpen:false})} 
          aria-labelledby="simple-dialog-title" 
          maxWidth="xs"
          open={mailFormatSelectionOpen}
          keepMounted
          scroll="paper"
          TransitionComponent={Transition}
          >
          <DialogTitle id="simple-dialog-title">Välj en email-mall</DialogTitle>
          <DialogContent dividers={true}>
            <List>
              <ListItem divider autoFocus button onClick={(event) => this.openEmail(event, null)}>
                <ListItemText primary="TOM" />
              </ListItem>
              {lookupEmailFormat.map((item, index) => {
                return (
                  <ListItem key={index} autoFocus button onClick={(event) => this.openEmail(event, index)}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
        </Dialog>
        <Collapse in={dialog.open} className="p-2">
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={this.onDialogClose}
                >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {dialog.text}
          </Alert>
        </Collapse>
        <MuiThemeProvider theme={this.theme}>
          <MaterialTable 
            columns={[
              { title: "Nr", field: "id", defaultSort:'desc', type:"numeric", editable:"never", defaultFilter: this.defaultFilters['id'] || null },
              { title: "Datum", field: "created", type:"date", editable:'never', defaultFilter: this.defaultFilters['created'] || null },
              { title: "Förnamn", field: "firstName", defaultFilter: this.defaultFilters['firstName'] || null },
              { title: "Efternamn", field: "lastName", defaultFilter: this.defaultFilters['lastName'] || null },
              { title: "Status", field: "status", initialEditValue: defaultStatus, lookup: lookupStatus },
              { title: "Personnr", field: "ssn", defaultFilter: this.defaultFilters['ssn'] || null },
              { title: "Syskon", field: "hasSibling", type: "boolean", cellStyle:{textAlign:'center'}, defaultFilter: this.defaultFilters['hasSibling'] || null },
              { title: "Startår", field: "startYear", defaultFilter: this.defaultFilters['startYear'] || null },
              {
                  title: "Startmånad", editable:'always', field: "startMonth", lookup: {
                      //undefined: 'Januari',
                      '0': 'Januari',
                      '1': 'Februari',
                      '2': 'Mars',
                      '3': 'April',
                      '4': 'Maj',
                      '5': 'Juni',
                      '6': 'Juli',
                      '7': 'Augusti',
                      '8': 'September',
                      '9': 'Oktober',
                      '10': 'November',
                      '11': 'December'
                  }, defaultFilter: this.defaultFilters['startMonth'] || null
              },
              { cellStyle:{width:'250px'}, title: "Språk", field: "language", editComponent: props => (<CountrySelect {...props} style={{padding:"6.3px 9px"}} all={this.state.data} width={300} variant="standard" />), defaultFilter: this.defaultFilters['language'] || null },
              { title: "Nuvarande", field: "present" },
              { title: "Enhet", field: "grade", initialEditValue: defaultGrade, lookup: lookupGrades, defaultFilter: this.defaultFilters['grade'] || null },
              { title: "Kön", field: "gender", editable: 'always', lookup: {
                'Boy': 'Pojke',
                'Girl': 'Flicka',
                'Unknown  ': 'Okänt'
              }, defaultFilter: this.defaultFilters['gender'] || null }
            ]}
            data={data}
            options={{
              addRowPosition: 'first',
              draggable: false,
              filtering: showFilter,
              pageSize: 10,
              exportAllData: true,
              selection: true,
              exportButton: true
            }}
            actions={[
              {
                icon: "filterList",
                tooltip: "Filter",
                isFreeAction: true,
                onClick: () => this.setState({showFilter:!showFilter})
              },
              {
                tooltip: 'Skicka email till valda ansökanden',
                icon: 'mail_outline',
                position:"toolbarOnSelect",
                onClick: this.onMailTo.bind(this)
              }
            ]}
            localization={{
              body: {
                emptyDataSourceMessage: 'Inga ansökningsuppgifter finns',
                addTooltip: 'Lägg till ansökan',
                deleteTooltip: 'Ta bort',
                editTooltip: 'Ändra',
                editRow: {
                  deleteText: 'Vill ni verkligen ta bort den här ansökan?',
                  cancelTooltip: 'Avbryt',
                  saveTooltip: 'Spara'
                }
              },
              header: {
                actions: ''
              },
              pagination: {
                labelDisplayedRows: '{from}-{to} av {count}',
                labelRowsSelect: 'rader',
                labelRowsPerPage: 'Rader per sida:',
                firstAriaLabel: 'Första sidan',
                firstTooltip: 'Första sidan',
                previousAriaLabel: 'Föregående sida',
                previousTooltip: 'Föregående sida',
                nextAriaLabel: 'Nästa sida',
                nextTooltip: 'Nästa sida',
                lastAriaLabel: 'Sista sidan',
                lastTooltip: 'Sista sidan'
              },
              toolbar: {
                nRowsSelected: "{0} rader valda",
                searchTooltip: 'Sök',
                searchPlaceholder: 'Sök',
                exportTitle: "Exportera",
                exportAriaLabel: "Exportera",
                exportName: "Exportera som CSV-fil (Excel)"
              }
            }}
            tableRef={this.tableRef}
            onFilterChange={(filters) => this.saveFilters(this.tableRef)}
            onSelectionChange={this.onRowsSelected.bind(this)}
            detailPanel={rowData => <Detail data={rowData} />}
            editable={{
              onRowAdd:this.onRowAdd, 
              onRowUpdate:this.onRowUpdate, 
              onRowDelete:this.onRowDelete
            }}
            title="Ansökningslista"/>
          </MuiThemeProvider>
      </div>
    );
  }

  saveFilters = (tableRef) => {
    if (tableRef === undefined || tableRef.current === undefined)
      return;

    const columns = tableRef.current.state.columns.map((column) => ({
      field: column.field,
      filterValue: column.tableData.filterValue
    })).reduce((acc, col) => { acc[col.field] = col.filterValue || null; return acc; }, {});
    console.log(columns);

    this.defaultFilters = columns;
  }

  render() {
    let contents = this.state.loading
      ? null
      : this.renderTable(this.state.data);

    return (
      <div>
        {contents}
      </div>
    );
  }

  onDialogClose(){
    let {dialog} = this.state;
    dialog.open = false;
    this.setState({dialog:dialog});
  }

  openEmail(event, id){
    let {selectedRows, lookupEmailFormat} = this.state;
    let result = selectedRows.flatMap((applicant) => {
      return applicant.parent.map((parent) => {
        return parent.email;
      });
    }).filter((item, index, self) => {
      return self.indexOf(item) === index;
    });

    let format = lookupEmailFormat[id];
    let link = "";

    if(format === undefined){
      link = `mailto:?bcc=${result.join(';')}`;
    } else {
      console.log(format.format);
      let unformat = this.stripHtml(format.format);
      console.log(unformat);
      link = `mailto:?bcc=${result.join(';')}&subject=${format.subject}&body=${encodeURIComponent(unformat)}`;
    }
    
    window.location.href = link;
    this.setState({mailFormatSelectionOpen:false});
  }

  stripHtml(html) {
    // html = html.replace(/<p><br><\/p>/g, "%0D");
    // html = html.replace(/<\/p>/g, "%0D");
    // html = html.replace(/<p>/g, "");
    // html = html.replace(/<br>/g, "%0D");
    html = html.replace(/<p><br><\/p>/g, "\n");
    html = html.replace(/<\/p>/g, "\n");
    html = html.replace(/<p>/g, "");
    html = html.replace(/<br>/g, "\n");
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  onMailTo(event, data){
    this.setState({mailFormatSelectionOpen:true});
    /*let {selectedRows} = this.state;
    let result = selectedRows.flatMap((applicant) => {
      return applicant.parent.map((parent) => {
        return parent.email;
      });
    }).filter((item, index, self) => {
      return self.indexOf(item) === index;
    });

    let link = `mailto:?bcc=${result.join(';')}`;
    console.log(link);
    window.location.href = link;
    console.log(window.location);*/
  }

  onRowsSelected(rows){
    let selectedRows = [];
    let total = rows.length;
    let count = 0;

    rows.forEach(async (item, index) => {
      let result = await this.populateSingleApplicant(item.id);
      selectedRows.push(result);

      count++;
      if(count === total){
        this.setState({selectedRows:selectedRows});
      }
    });
  }

  onRowAdd(newData){
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        {
          const data = this.state.data;
          if(!newData.hasOwnProperty('gender') || newData.gender === null || newData.gender === ''){
            newData.gender = this.parseSSN(newData.ssn);
          }
          newData.created = new Date();
          try {
            const response = await addApplicant(newData);
            const tableData = newData.tableData;
            newData = await response.json();
            newData.tableData = tableData;
            console.log(newData);
          } catch {
            let {dialog} = this.state;
            dialog.open = true;
            dialog.text = 'Kan inte lägga till ny ansökan.';
            this.setState({dialog:dialog});
            reject();
            return;
          }
          data.push(newData);
          this.setState({ data }, () => resolve());
        }
        resolve();
      }, 1000)
    })
  }
    
  onRowUpdate(newData, oldData) {
    const { lookupGrades, lookupStatus, dialog } = this.state;

    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        {
          const data = this.state.data;
          const index = data.indexOf(oldData);
          if(!newData.hasOwnProperty('gender') || newData.gender === null || newData.gender === ''){
            newData.gender = this.parseSSN(newData.ssn);
          }

          for (const property in newData) {
            if (newData[property] === oldData[property])
              continue;

            switch (property) {
              case 'applicantHistory':
              case 'parent':
              case 'sibling':
                break;
              case 'grade':
                addAppLogEntry(property, newData.id, lookupGrades[oldData[property]], lookupGrades[newData[property]]);
                break;
              case 'status':
                addAppLogEntry(property, newData.id, lookupStatus[oldData[property]], lookupStatus[newData[property]]);
                break;
              default:
                addAppLogEntry(property, newData.id, oldData[property], newData[property]);
                break;
            }
          }


          //console.log(this.state.lookupStatus[+newData.status]);
          const response = await updateApplicant(oldData.id, newData);
          //console.log(response);

          if(response.status === 409){
            // Duplicate error
            dialog.open = true;
            dialog.text = 'Ansökan med detta personnr finns redan.';
            this.setState({ dialog: dialog });
            reject();
            return;
          } else if (response.status == 400) {
            dialog.open = true;
            dialog.text = 'Okänt fel, kontakta administratör.';
            this.setState({ dialog: dialog });
            reject();
            return;
          }

          data[index] = newData;
          this.setState({ data }, () => resolve());
        }
        resolve()
      }, 1000)
    })
  }
    
  onRowDelete(oldData){
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        {
          let data = this.state.data;
          const index = data.indexOf(oldData);

          try {
            await deleteApplicant(data[index].id);
          } catch {
            let {dialog} = this.state;
            dialog.open = true;
            dialog.text = 'Kan inte ta bort ansökan.';
            this.setState({dialog:dialog});
            reject();
            return;
          }

          data.splice(index, 1);
          this.setState({ data }, () => resolve());
        }
        resolve()
      }, 1000)
    })
  }

  parseSSN(ssn){
    if(ssn == null || ssn === "" || ssn.length < 10)
      return "Unknown";

    let num = +ssn.slice(-2);
    return (num % 2 === 0)? 'Girl' : 'Boy';
  }

  async populateData() {
    this.props.isLoading(true);

    const applicantResponse = await getApplicants();
    if(applicantResponse == null){
      this.props.isLoading(false);
      return;
    }

    const applicants = await applicantResponse.json();
    applicants.forEach(applicant => {
      if(applicant.status === null)
        applicant.status = "";

      if(applicant.startMonth === null)
        applicant.startMonth = "";
    });
    const status = (await (await getSettingById(2)).json()).filter((item) => item.enabled === true);
    const grades = (await (await getSettingById(1)).json()).filter((item) => item.enabled === true);
    const emailFormat = await (await getEmailFormats()).json();

    const defaultStatus = status.find((item) => item.default === true).id;
    const defaultGrade = grades.find((item) => item.default === true).id;

    this.setState({ 
      data: applicants,
      lookupEmailFormat: emailFormat,
      defaultStatus: defaultStatus,
      lookupStatus: makeLookupFormat(status, 'value'),
      defaultGrade: defaultGrade,
      lookupGrades: makeLookupFormat(grades, 'value'),
      loading: false 
    });
    this.props.isLoading(false);
  }

  async populateSingleApplicant(id){
    const response = await getApplicantById(id);
    return await response.json();
  }
}
