import authService from '../components/api-authorization/AuthorizeService';

async function handleResponse(response){
  if(response === undefined || response === null){
    //await authService.signIn();
    return;
  }

  if(response.status === 401){
    await authService.signOut();
  }
}

export async function Get(addr){
  const token = await authService.getAccessToken();
  const response = await fetch(addr, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  });

  if(!response.ok){
    handleResponse();

    return null;
  }

  return response;
}

export async function GetById(addr, id){
  const token = await authService.getAccessToken();
  const response = await fetch(`${addr}/${id}`, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  });

  if(!response.ok){
    handleResponse();
    return null;
  }

  return response;
}

export async function Add(addr, data, auth){
  let token;
  if(auth){
    token = await authService.getAccessToken();
  }
  
  let header = !token 
    ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      } 
    : { 
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

  const response = await fetch(addr, {
    headers: header,
    method: 'POST',
    body: JSON.stringify(data)
  });

  if(!response.ok){
    handleResponse();
    //return null;
  }

  return response;
}

export async function Update(addr, id, data){
  const token = await authService.getAccessToken();
  const response = await fetch(`${addr}/${id}`, {
    headers: !token 
      ? {} 
      : { 
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    method: 'PUT',
    body: JSON.stringify(data)
  });

  if(!response.ok){
    handleResponse();
    return null;
  }

  return response;
}

export async function Delete(addr, id){
  const token = await authService.getAccessToken();
  const response = await fetch(`${addr}/${id}`, {
    headers: !token 
      ? {} 
      : { 'Authorization': `Bearer ${token}` },
    method: 'DELETE'
  });

  if(!response.ok){
    handleResponse();
    return null;
  }

  return response;
}