import React, { Component } from 'react';
import { addSetting, updateSetting, deleteSetting } from '../../API/setting';
import MaterialTable, { MTableBodyRow, MTableBody } from "material-table";
import { Collapse, IconButton } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CustomMTableBodyRow from './CustomMTableBodyRow';

export class GradeList extends Component {
  static displayName = GradeList.name;

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dialog: { open: false, text: '' }
    };

    this.onDialogClose = this.onDialogClose.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let { data } = prevState;

    if (data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  onBeforeCapture = () => {
    /*...*/
  };

  onBeforeDragStart = () => {
    /*...*/
  };

  onDragStart = () => {
    /*...*/
  };
  onDragUpdate = () => {
    /*...*/
  };
  onDragEnd = () => {
    // the only one that is required
  };

  renderTable(data) {
    let { dialog } = this.state;

    return (
      <div>
        <DragDropContext
          onBeforeCapture={this.onBeforeCapture}
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >

          <Collapse in={dialog.open} className="p-2">
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.onDialogClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {dialog.text}
            </Alert>
          </Collapse>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <MaterialTable
                  columns={[
                    { title: "Id", field: "id", type: "numeric", editable: "never", hidden: true, cellStyle: { width: "50px" } },
                    { title: "#", field: "order", type: "numeric", defaultSort:'asc', editable: "onUpdate", hidden: false, cellStyle: { width: "30px" } },
                    { title: "Namn", field: "value" },
                    { title: "Aktiverad", field: "enabled", type: "boolean", cellStyle: { width: "50px" } }
                  ]}
                  data={data}
                  options={{
                    addRowPosition: 'first',
                    draggable: false,
                    filtering: false,
                    selection: false,
                    search: false,
                    paging: false
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 'Inga årskurser finns',
                      addTooltip: 'Lägg till årskurs',
                      deleteTooltip: 'Ta bort',
                      editTooltip: 'Ändra',
                      editRow: {
                        deleteText: 'Vill ni verkligen ta bort den här årskursen?',
                        cancelTooltip: 'Avbryt',
                        saveTooltip: 'Spara'
                      }
                    },
                    header: {
                      actions: ''
                    }
                  }}
                  /*components={{
                    Row: props => (
                      <Draggable draggableId={props.index.toString()} index={props.index}>
                        {(provided, snapshot) => {
                          //console.log(provided);
                          return (
                            <CustomMTableBodyRow 
                              //{...provided.innerRef}} 
                              innerRef={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              {...props}
                            /> 
                          );
                        }}
                      </Draggable>
                    )
                  }}*/
                  editable={{
                    onRowAdd: this.onRowAdd.bind(this),
                    onRowUpdate: this.onRowUpdate.bind(this),
                    onRowDelete: this.onRowDelete.bind(this)
                  }}
                  title="Årskurslista" />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  render() {
    let { data } = this.state;
    let content = data !== null
      ? this.renderTable(data)
      : "";

    return (
      <div>
        {content}
      </div>
    );
  }

  onDialogClose() {
    let { dialog } = this.state;
    dialog.open = false;
    this.setState({ dialog: dialog });
  }

  onRowAdd(newData) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        {
          if (!newData.hasOwnProperty('value') || newData.value === '') {
            let { dialog } = this.state;
            dialog.open = true;
            dialog.text = 'Kan inte lägga till rad utan årskursnamn.';
            this.setState({ dialog: dialog });
            reject();
            return;
          }

          const data = this.state.data;
          newData.settingType = this.props.settingTypeId;
          const response = this.apiAddNew(newData);
          newData = await response;
          data.push(newData);
          this.setState({ data });
          this.props.onChange(data);
        }
        resolve();
      }, 1000)
    })
  }

  onRowUpdate(newData, oldData) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        {
          const data = this.state.data;
          const index = data.indexOf(oldData);
          await updateSetting(oldData.id, newData);
          data[index] = newData;
          this.setState({ data }, () => resolve());
          this.props.onChange(data);
        }
        resolve()
      }, 1000)
    })
  }

  onRowDelete(oldData) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        {
          let data = this.state.data;
          const index = data.indexOf(oldData);
          try {
            await deleteSetting(data[index].id);
          } catch {
            console.log("foreign key restraint error");
            let { dialog } = this.state;
            dialog.open = true;
            dialog.text = 'Kan inte ta bort årskurs.';
            this.setState({ dialog: dialog });
            reject();
            return;
          }
          data.splice(index, 1);
          this.setState({ data }, () => resolve());
          this.props.onChange(data);
        }
        resolve()
      }, 1000)
    })
  }

  async apiAddNew(data) {
    let realData = {
      'settingType': data.settingType,
      'value': data.value,
      'enabled': data.hasOwnProperty('enabled') ? data.enabled : false
    };

    const response = await addSetting(realData);
    return await response.json();
  }
}
